// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { globalEnvironment } from './environment.global';

export const environment = {
  production: false,
  offline: false,
  ...globalEnvironment,
  // apiBaseUrl: 'http://localhost/quest',
  apiBaseUrl: 'https://api.questapp.flexsin.org',
  //apiBaseUrl: 'https://questapp.dev/3streams',
  ssoApiBaseUrl: 'https://sso.questapp.in',
  firebase: {
    // Also present in firebase-messaging-sw.js
    apiKey: 'AIzaSyCeBI-7aGsUVwuzsP9AbdaSitFY8rvA5fo', // Auth / General Use
    authDomain: 'my-quest-65f06.firebaseapp.com', // Auth with popup/redirect
    projectId: 'my-quest-65f06', // General Use
    storageBucket: 'my-quest-65f06.appspot.com', // Storage
    messagingSenderId: '1002327304037', // Cloud Messaging (Also present in manifest.json)
    appId: '1:1002327304037:web:f4428bf2f2c319cfaea38c', // General Use
    measurementId: 'G-XPX13ZQKWZ',
  },
  freeScoutUrl: 'https://freescout.questapp.dev/help/1551065838',
  gaTag: 'UA-227460719-1',
  bearerExcludedUrls: ['/sso-service/public/api/v1/get-profile'],
  smartlook: {
    projectKey: 'c95434d3224302939808c05d9b86d1a31aef5133', // 'f6547b791087ae2438936bbc8adbfb0d00539e17',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
